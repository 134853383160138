// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword  } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
// import OpenAI from "openai";



const firebaseConfig = {
  apiKey: "AIzaSyDjJk0vAzL8T6Ug_F_lX56ytarBJJaX9Lw",
  authDomain: "scholar-406702.firebaseapp.com",
  projectId: "scholar-406702",
  storageBucket: "scholar-406702.appspot.com",
  messagingSenderId: "447006696640",
  appId: "1:447006696640:web:494bc33238f9a01698b925",
  measurementId: "G-N8T8BWM39C"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth();
const firestore = getFirestore(firebaseApp);
// const analytics = getAnalytics(app)
const gProvider = new GoogleAuthProvider();

export {
  auth,
  firebaseApp,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  firestore,
  signInWithPopup,
  gProvider,
  createUserWithEmailAndPassword,
}
